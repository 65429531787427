.fafascroller-container {
    position: relative;

    .sticky-section {
        position: sticky;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        bottom: 0;
    }
}
