.fafascroller-container.scroller-container {
  background-color: #191919;
  // padding: 0 20vw 0;
}

.small {
  margin: auto;
  width: 70vw !important;
}

body {
  margin: 0;
}
