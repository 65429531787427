.DaysWithout {
  &__container {
    height: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    h1,
    h2 {
      color: white;
    }
  }
}
