.MainTitle {
  &__container {
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
  }
}
